<template>
  <b-container>
    <b-form v-on:submit.prevent enctype="multipart/form-data">
      <b-row align-h="center">
        <b-col cols="8">
          <b-card>
            <label for="items" class="text-primary font-weight-bolder mb-1"
              >Upload Dokumen</label
            >
            <b-row>
              <b-col>
                <b-form-group>
                  <v-select
                    v-model="typeSelected"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="text"
                    :options="typeOption"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  :state="errors"
                  label="File"
                  label-for="File"
                  invalid-feedback="File is required"
                >
                  <b-form-file
                    v-model="file"
                    placeholder="Upload file disini..."
                    drop-placeholder="Upload file disini..."
                    id="extension"
                    accept="application/pdf"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="mt-2"
              @click="postData()"
            >
              Kirim
            </b-button>
          </b-card>
        </b-col>
      </b-row>
    </b-form>
  </b-container>
</template>

<script>
import {
  BImg,
  BFormFile,
  BFormSelect,
  BListGroupItem,
  BAvatar,
  BCard,
  BCardText,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
  BContainer,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import Prism from "vue-prism-component";
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ImageUploader from "vue-image-upload-resize";
import { $themeConfig } from "@themeConfig";
import Cleave from "vue-cleave-component";
import "quill/dist/quill.snow.css";
import { quillEditor } from "vue-quill-editor";
import flatPickr from "vue-flatpickr-component";
import { data } from "vue-echarts";
import vSelect from 'vue-select'

export default {
  components: {
    quillEditor,
    ToastificationContent,
    ImageUploader,
    Cleave,
    ValidationProvider,
    ValidationObserver,
    BImg,
    BFormFile,
    BFormSelect,
    BListGroupItem,
    BAvatar,
    BCard,
    BCardText,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    Prism,
    BContainer,
    flatPickr,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      file: null,
      form: {
        file: null,
      },
      errors: "",
      errMessage: "",
      typeSelected: "",
      typeOption:[
        {
          text: 'Cara Pembayaran',
          value: 1,
        },{
          text: 'Cara Perpanjangan Sewa',
          value: 2,
        },{
          text: 'Cara Pemberhentian Sewa',
          value: 3,
        },{
          text: 'Syarat & Ketentuan',
          value: 4,
        }
      ]
    };
  },
  setup() {
    // App Name
    const { baseUrl, apiUrl } = $themeConfig.app;
    return {
      baseUrl,
      apiUrl,
    };
  },
  methods: {
    postData() {
      const formData = new FormData();
      formData.append("file", this.file);
      formData.append("type", this.typeSelected.value);

      this.$http
        .post("document/add", formData)
        .then((response) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notifikasi",
              icon: "BellIcon",
              text: "File syarat & ketentuan berhasil diupdate",
              variant: "success",
            },
          });
          setTimeout(() => {
            this.$router.push("/documents");
          }, 2000);
          // console.log(response.data.data)
        })
        .catch((errors) => {
          this.errMessage = errors.response.data.message;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notifikasi",
              icon: "BellIcon",
              text: this.errMessage,
              variant: "warning",
            },
          });
          console.log(errors.response);
        });
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>